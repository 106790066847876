/* eslint-disable no-undef */

const budgetBarChartHorizontal = {
  series: [
    {
      name: "Monthly Hours",
      data: [],
    },
  ],
  chart: {
    height: 350,
    type: "bar",
    fontFamily: "'Cerebri Sans', sans-serif",
  },
  plotOptions: {
    bar: {
      horizontal: true,
      borderRadius: 8,
      columnWidth: "50%",
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  colors: ["var(--theme-color,#2c7be5)"],
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  xaxis: {
    labels: {
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}`.replace(
          /(\d)(?=(\d{3})+(?!\d))/g,
          "$1,"
        );
      },
    },
  },
  dataLabels: {
    enabled: true,
    textAnchor: "start",
    formatter: function (val, { seriesIndex, dataPointIndex, w }) {
      return (
        `
        ${+parseFloat(
          (val /
            w.config.series[seriesIndex].data[dataPointIndex].goals?.[0]
              .value) *
            100
        ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "%"
      );
    },
    style: {
      colors: ["#000"],
    },
  },
  grid: {
    borderColor: "transparent",
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    custom: function ({ seriesIndex, dataPointIndex, w }) {
      const type = w.config.series[seriesIndex].data[dataPointIndex].t;
      let seriesValue = w.config.series[seriesIndex].data[dataPointIndex].y;
      let goalValue =
        w.config.series[seriesIndex].data[dataPointIndex].goals?.[0].value;

      seriesValue = `${+parseFloat(seriesValue).toFixed(2)}`.replace(
        /(\d)(?=(\d{3})+(?!\d))/g,
        "$1,"
      );
      goalValue = `${+parseFloat(goalValue).toFixed(2)}`.replace(
        /(\d)(?=(\d{3})+(?!\d))/g,
        "$1,"
      );
      if (type == "money") {
        seriesValue = `$${seriesValue}`;
        goalValue = `$${goalValue}`;
      } else {
        seriesValue = `${seriesValue}H`;
        goalValue = `${goalValue}H`;
      }
      return `
  <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${w.config.series[seriesIndex].data[dataPointIndex].x}</div>
  <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
    <div class="apexcharts-tooltip-y-group">
      <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
        <span class="apexcharts-tooltip-text-y-label">
          <div>
            <div style="display: flex;align-items:center;">
              <span class="apexcharts-tooltip-marker" style="background-color: ${w.config.colors[0]};"></span> ${w.config.series[seriesIndex].name}:
            </div>
          </div>
        </span>
        <span class="apexcharts-tooltip-text-y-value">${seriesValue}</span>
      </div>

      <div class="apexcharts-tooltip-goals-group" style="padding: 0px 10px 4px;">
        <span class="apexcharts-tooltip-text-goals-label">
          <div>
            <div style="display: flex;"><span class="apexcharts-tooltip-marker" style="background-color: ${w.config.series[seriesIndex].data[dataPointIndex].goals?.[0].strokeColor}; height: 3px; border-radius: 0; top: 5px;"></span>${w.config.series[seriesIndex].data[dataPointIndex].goals?.[0].name}</div>
          </div>
        </span><span class="apexcharts-tooltip-text-goals-value">
          <div>
            <div>${goalValue}</div>
          </div>
        </span>
      </div>
      <div class="apexcharts-tooltip-z-group" style="display: flex;justify-content:center"><span class="apexcharts-tooltip-text-z-label"></span><span class="apexcharts-tooltip-text-z-value">${w.config.series[seriesIndex].data[dataPointIndex].z}</span></div>
    </div>
  </div>
`;
    },
  },
  legend: {
    show: true,
    showForSingleSeries: true,
    customLegendItems: ["Spending", "Budgeted Amount"],
    markers: {
      fillColors: ["var(--theme-color,#2c7be5)", "#d7263d"],
    },
  },
};
const budgetBarChart = {
  series: [
    {
      name: "Monthly Hours",
      data: [],
    },
  ],
  chart: {
    height: 350,
    type: "bar",
    fontFamily: "'Cerebri Sans', sans-serif",
  },
  dataLabels: {
    enabled: true,
    formatter: function (val, { seriesIndex, dataPointIndex, w }) {
      return (
        `
        ${+parseFloat(
          (val /
            w.config.series[seriesIndex].data[dataPointIndex].goals?.[0]
              .value) *
            100
        ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "%"
      );
    },
    style: {
      colors: ["#000"],
    },
  },
  stroke: {
    width: 0,
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      columnWidth: "50%",
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  colors: ["var(--theme-color,#2c7be5)"],
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  zoom: {
    enabled: true,
  },
  grid: {
    row: {
      colors: ["transparent", "transparent"],
      opacity: 0,
    },
    borderColor: "transparent",
  },
  xaxis: {
    labels: {
      rotate: -45,
      hideOverlappingLabels: true,
    },
    categories: [],
    tickPlacement: "on",
  },
  yaxis: {
    title: {
      text: "",
    },
    labels: {
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}`.replace(
          /(\d)(?=(\d{3})+(?!\d))/g,
          "$1,"
        );
      },
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    custom: function ({ seriesIndex, dataPointIndex, w }) {
      const type = w.config.series[seriesIndex].data[dataPointIndex].t;
      let seriesValue = w.config.series[seriesIndex].data[dataPointIndex].y;
      let goalValue =
        w.config.series[seriesIndex].data[dataPointIndex].goals?.[0].value;

      seriesValue = `${+parseFloat(seriesValue).toFixed(2)}`.replace(
        /(\d)(?=(\d{3})+(?!\d))/g,
        "$1,"
      );
      goalValue = `${+parseFloat(goalValue).toFixed(2)}`.replace(
        /(\d)(?=(\d{3})+(?!\d))/g,
        "$1,"
      );
      if (type == "money") {
        seriesValue = `$${seriesValue}`;
        goalValue = `$${goalValue}`;
      } else {
        seriesValue = `${seriesValue}H`;
        goalValue = `${goalValue}H`;
      }
      return `
  <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${w.config.series[seriesIndex].data[dataPointIndex].x}</div>
  <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
    <div class="apexcharts-tooltip-y-group">
      <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
        <span class="apexcharts-tooltip-text-y-label">
          <div>
            <div style="display: flex;align-items:center;">
              <span class="apexcharts-tooltip-marker" style="background-color: ${w.config.colors[0]};"></span> ${w.config.series[seriesIndex].name}:
            </div>
          </div>
        </span>
        <span class="apexcharts-tooltip-text-y-value">${seriesValue}</span>
      </div>
      <div class="apexcharts-tooltip-goals-group" style="padding: 0px 10px 4px;">
        <span class="apexcharts-tooltip-text-goals-label">
          <div>
            <div style="display: flex;"><span class="apexcharts-tooltip-marker" style="background-color: ${w.config.series[seriesIndex].data[dataPointIndex].goals?.[0].strokeColor}; height: 3px; border-radius: 0; top: 5px;"></span>${w.config.series[seriesIndex].data[dataPointIndex].goals?.[0].name}</div>
          </div>
        </span><span class="apexcharts-tooltip-text-goals-value">
          <div>
            <div>${goalValue}</div>
          </div>
        </span>
      </div>
      <div class="apexcharts-tooltip-z-group" style="display: flex;justify-content:center"><span class="apexcharts-tooltip-text-z-label"></span><span class="apexcharts-tooltip-text-z-value">${w.config.series[seriesIndex].data[dataPointIndex].z}</span></div>
    </div>
  </div>
`;
    },
  },
  legend: {
    show: true,
    showForSingleSeries: true,
    customLegendItems: ["Spending", "Budgeted Amount"],
    markers: {
      fillColors: ["var(--theme-color,#2c7be5)", "#d7263d"],
    },
  },
};

const providerBudgetBarChart = {
  series: [
    {
      name: "Spending Percentage",
      data: [],
    },
  ],
  chart: {
    height: 350,
    type: "bar",
    stacked: true,
    fontFamily: "'Cerebri Sans', sans-serif",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 0,
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      columnWidth: "50%",
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  colors: ["var(--theme-color,#2c7be5)"],
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  zoom: {
    enabled: true,
  },
  grid: {
    row: {
      colors: ["transparent", "transparent"],
      opacity: 0,
    },
    borderColor: "transparent",
  },
  xaxis: {
    labels: {
      rotate: -45,
      hideOverlappingLabels: true,
    },
    categories: [],
    tickPlacement: "on",
  },
  yaxis: {
    title: {
      text: "",
    },
    labels: {
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}%`;
      },
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    custom: function ({ seriesIndex, dataPointIndex, w }) {
      return `
      <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
        ${
          w.config.series[seriesIndex].data[dataPointIndex].fullName ||
          w.config.series[seriesIndex].data[dataPointIndex].x
        }
      </div>
      <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
          <span class="apexcharts-tooltip-marker" style="background-color: ${
            w.config.colors[0]
          };"></span>
          <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
              <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                <span class="apexcharts-tooltip-text-y-label">Spending percentage for selected month: </span>
                <span class="apexcharts-tooltip-text-y-value">
                  ${w.config.series[seriesIndex].data[dataPointIndex].y}%
                </span>
              </div>
          </div>
      </div>
      <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
          <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
              <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                <span class="apexcharts-tooltip-text-y-label">Spending Percentage: </span>
                <span class="apexcharts-tooltip-text-y-value">
                  ${
                    w.config.series[seriesIndex].data[dataPointIndex]
                      .spendingPercentage
                  }%
                </span>
              </div>
          </div>
      </div>
        `;
    },
  },
};

const providerBudgetBarChartHorizontal = {
  series: [
    {
      name: "Spending Percentage",
      data: [],
    },
  ],
  chart: {
    height: 350,
    type: "bar",
    fontFamily: "'Cerebri Sans', sans-serif",
  },
  plotOptions: {
    bar: {
      horizontal: true,
      borderRadius: 8,
      columnWidth: "50%",
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  colors: ["var(--theme-color,#2c7be5)"],
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  xaxis: {
    title: {
      text: "",
    },
    categories: [],
    labels: {
      hideOverlappingLabels: true,
      showDuplicates: true,
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}%`;
      },
    },
  },
  dataLabels: {
    textAnchor: "start",
    enabled: true,
    formatter: function (val) {
      return `
        ${parseFloat(val).toFixed(2)}%
      `;
    },
    style: {
      colors: ["#000"],
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    custom: function ({ seriesIndex, dataPointIndex, w }) {
      return `
      <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
        ${
          w.config.series[seriesIndex].data[dataPointIndex].fullName ||
          w.config.series[seriesIndex].data[dataPointIndex].x
        }
      </div>
      <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
          <span class="apexcharts-tooltip-marker" style="background-color: ${
            w.config.colors[0]
          };"></span>
          <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
              <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                <span class="apexcharts-tooltip-text-y-label">Spending percentage for selected month: </span>
                <span class="apexcharts-tooltip-text-y-value">
                  ${w.config.series[seriesIndex].data[dataPointIndex].y}%
                </span>
              </div>
          </div>
      </div>
      <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
          <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
              <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                <span class="apexcharts-tooltip-text-y-label">Spending Percentage: </span>
                <span class="apexcharts-tooltip-text-y-value">
                  ${
                    w.config.series[seriesIndex].data[dataPointIndex]
                      .spendingPercentage
                  }%
                </span>
              </div>
          </div>
      </div>
        `;
    },
  },
};

const getBudgetBarChart = function () {
  return _.cloneDeep(budgetBarChart);
};
const getBudgetBarChartHorizontal = function () {
  return _.cloneDeep(budgetBarChartHorizontal);
};

const getProviderBudgetBarChart = function () {
  return _.cloneDeep(providerBudgetBarChart);
};

const getProviderBudgetBarChartHorizontal = function () {
  return _.cloneDeep(providerBudgetBarChartHorizontal);
};

export default {
  getBudgetBarChart,
  getBudgetBarChartHorizontal,
  getProviderBudgetBarChart,
  getProviderBudgetBarChartHorizontal,
};
